import React from "react";
import pageImage from "images/HomepageLogo.png";
import {
  FullBackground,
  HowECLVideo,
  HowECLWorksNav,
  Layout,
  SEO,
} from "components";
import { graphql } from "gatsby";

const ExplorePage = ({ data, location }) => {
  const title = "Comprehensive Model Quality Data in a Knowledge Graph";
  const description = "Answer any question of your exeperimental in seconds";
  return (
    <FullBackground gradient>
      <HowECLWorksNav section={location && location.pathname} />
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <HowECLVideo data={data.section} widescreen />
      </Layout>
    </FullBackground>
  );
};

export default ExplorePage;

export const query = graphql`
  query HowECLWorksSectionExplore {
    section: howEclWorksJson(section: { eq: "explore" }) {
      title
      videoURL
      paragraph
      btnText
      nextRoute
      controls {
        controls
      }
      bookmarks {
        text
        time
      }
    }
  }
`;
